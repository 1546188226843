<template>
  <div>
    <v-card class="pa-8">
      <v-row justify="center">
        <v-col class="text-left">
          <p class="text-h5">{{ $t("global.list_filter") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="text-left">
          <AgentFilter
            v-model="agentFilter"
            data-cy="matchesFilters"
            :mine-text="$t('listing.job_offers.filters.mine')"
            :all-text="$t('listing.job_offers.filters.all')"
            @input="handleAgentFilterChanged"
          ></AgentFilter>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="search"
            data-cy="input-search"
            append-icon="mdi-magnify"
            :label="$t('listing.job_offers.search')"
            single-line
            hide-details
            @input="handleSearchChanged"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-8 mt-4 fill-height">
      <v-row no-gutters class="d-print-none">
        <v-col>
          <DataListTitle
            :title="`${totalItems} ${$t('listing.job_offers.registered.plural')}`"
            :is-loading="loading"
          />
        </v-col>
      </v-row>
      <div>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          :footer-props="{
            'items-per-page-text': $t('listing.job_offers.pagination'),
            'items-per-page-options': itemsPerPageOptions
          }"
          multi-sort
          mobile-breakpoint="811"
          @update:options="handleTableOptionUpdated"
        >
          <template v-slot:item.actions="{ item }">
            <div class="py-2 nowrap">
              <v-btn
                color="default" small
                @click="$emit('show-offer-activities', item.id)"
              >
                {{ $t('activities.explicit') }}
              </v-btn>
              <v-btn
                color="primary"
                class="ml-1"
                small
                @click="$emit('show-offer-details', item.id)"
              >
                {{ $t('listing.job_offers.details') }}
              </v-btn>
            </div>
          </template>
          <template v-slot:item.rate="{ value }">
            <span>{{ value }}%</span>
          </template>
          <template v-slot:item.updated_at="{ value }">
            <span>{{ formatDate(value) }}</span>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>
<script>
import AgentFilter from "@/components/common/AgentFilter.vue";
import DataListTitle from '@/components/layout/DataListTitle.vue'
import DataTablesMixin from '@/mixins/dataTablesMixin'
import {mapActions, mapState} from 'vuex'
import {filterManagement} from "@/services/filters"
import DatesMixin from '@/mixins/datesMixin'
import AgentFilterMixin from '@/mixins/agentFilterMixin'
import SearchFilterMixin from '@/mixins/searchFilterMixin'

export default {
  components: {DataListTitle, AgentFilter },
  mixins: [DataTablesMixin, DatesMixin, AgentFilterMixin, SearchFilterMixin],
  props: {},
  data() {
    return {
      headers: [
        {
          text: this.$t('listing.headers.id'),
          value: 'id'
        },
        {
          text: this.$t('listing.job_offers.headers.name'),
          value: 'name',
          align: 'start'
        },
        {
          text: this.$t('listing.job_offers.headers.employer_name'),
          value: 'employer_name'
        },
        {
          text: this.$t('listing.job_offers.headers.employer_city'),
          value: 'place'
        },
        {
          text: this.$t('listing.job_offers.headers.rate'),
          value: 'rate'
        },
        {
          text: this.$t('listing.job_offers.headers.updated_at'),
          value: 'updated_at',
        },
        {
          value: 'actions',
          align: 'right'
        }
      ]
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('jobOffers', ['storedFilters']),
  },

  methods: {
    ...mapActions('jobOffers', ['getJobOffers']),

    getDataFromApi() {
      this.loading = true

      const args = {
        ...filterManagement.getTableOptionsFromUrlQuery(this.$route),
        search: filterManagement.getSearchFromUrlQuery(this.$route),
        mine: filterManagement.getAgentFilterFromUrlQuery(this.$route)
      }

      args.sortBy = args.sortBy.map(attr => (attr === 'employer_name') ? 'employer_representative.employer.name' : attr)
      args.status__not = 'ARCHIVED'

      this.getJobOffers(args).then(data => {
        this.items = data.items
        this.totalItems = data.count
        this.loading = false
      })
    },
  }
}
</script>
