<template>
  <div>
    <v-btn-toggle v-model="filterValue" mandatory color="primary" :dense="dense">
      <v-btn data-cy="agent-filter-mine" :value="mine">{{ mineText }} </v-btn>
      <v-btn data-cy="agent-filter-all" :value="all">{{ allText }}</v-btn>
    </v-btn-toggle>
  </div>
</template>
<script>
import { AgentFilterValues } from "@/helpers/agentFilter";

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    mineText: {
      type: String,
      required: true
    },
    allText: {
      type: String,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filterValue: this.value,
      mine: AgentFilterValues.Mine,
      all: AgentFilterValues.All
    }
  },
  watch: {
    filterValue:{
      handler() {
        this.$emit('input', this.filterValue)
      }
    },
    value: {
      handler() {
        this.filterValue = this.value
      }
    }
  }
}
</script>
<style scoped></style>
