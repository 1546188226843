<template>
  <div>
    <PageTitle
      icon="mdi-briefcase"
      :title="$t('listing.job_offers.title')"
    >
      <template v-slot:actions>
        <v-btn color="primary" class="ml-2" @click="createOffer">
          {{ $t('listing.job_offers.create') }} <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
    </PageTitle>
    <OffersList
      @show-offer-details="offerDetails"
      @show-offer-activities="offerActivities"
    />
  </div>
</template>

<script>
import OffersList from '@/components/offers/OffersList.vue'
import PageTitle from '@/components/layout/PageTitle.vue'
import RouterMixin from '@/mixins/routerMixin'

export default {
  components: { PageTitle, OffersList },
  mixins: [RouterMixin],
  methods: {
    createOffer() {
      this.routerPush('/offers/details')
    },
    offerDetails(offerId) {
      this.routerPush(`/offers/${offerId}/details`)
    },
    offerActivities(offerId) {
      this.routerPush(`/offers/${offerId}/activities`)
    }
  }
}
</script>

<style scoped></style>
