import {filterManagement} from "@/services/filters"

export default {
  data() {
    return {
      agentFilter: filterManagement.getAgentFilterFromUrlQuery(this.$route),
    }
  },
  mounted() {
    window.addEventListener("popstate", this.handleAgentFilterOnNavigatingBack);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.handleAgentFilterOnNavigatingBack);
  },
  methods: {
    handleAgentFilterChanged() {
      filterManagement.resetPagination(this.options)
      filterManagement.setAgentFilterInUrlQuery(this.agentFilter)
      this.debounceGetDataFromApi()
    },
    handleAgentFilterOnNavigatingBack(event) {
      if (event?.type === 'popstate') {
        this.agentFilter = filterManagement.getAgentFilterFromUrlQuery(this.$route)
        this.debounceGetDataFromApi()
      }
    },
  }
}
